<template>
  <layout>
    <template slot="body">
      <div class="margin-container">
        <span class="page-title">
          {{ $t('pages.privacyPolicy.title') }}
        </span>

        <span class="section--title">
          {{ $t('pages.privacyPolicy.1.title') }}
        </span>
        <span v-html="$t('pages.privacyPolicy.1.1')" />
        <span v-html="$t('pages.privacyPolicy.1.2')" />
        <span v-html="$t('pages.privacyPolicy.1.3')" />

        <span class="section--title">
          {{ $t('pages.privacyPolicy.2.title') }}
        </span>
        <span v-html="$t('pages.privacyPolicy.2.1')" />
        <span v-html="$t('pages.privacyPolicy.2.2')" />
        <ul>
          <li
            v-for="(line, index) in $t('pages.privacyPolicy.2.3')"
            :key="`2-3-line-${index}`"
          >
            <span v-html="line" />
          </li>
        </ul>

        <span class="section--title">
          {{ $t('pages.privacyPolicy.3.title') }}
        </span>
        <span v-html="$t('pages.privacyPolicy.3.1')" />
        <span
          class="section--subtitle"
          v-html="$t('pages.privacyPolicy.3.a.title')"
        />
        <span v-html="$t('pages.privacyPolicy.3.a.1')" />
        <span v-html="$t('pages.privacyPolicy.3.a.2')" />
        <ul>
          <div
            v-for="(line, index) in $t('pages.privacyPolicy.3.a.3')"
            :key="`3-a-3-line-${index}`"
          >
            <li v-if="typeof line === 'string'">
              <span v-html="line" />
            </li>
            <ul v-else>
              <li
                v-for="(point, pointIndex) in line"
                :key="`3-a-3-line-${index}-point-${pointIndex}`"
              >
                <span v-html="point" />
              </li>
            </ul>
          </div>
        </ul>
        <span v-html="$t('pages.privacyPolicy.3.a.4')" />
        <span v-html="$t('pages.privacyPolicy.3.a.5')" />
        <span v-html="$t('pages.privacyPolicy.3.a.6')" />
        <span
          class="section--subtitle"
          v-html="$t('pages.privacyPolicy.3.b.title')"
        />
        <span v-html="$t('pages.privacyPolicy.3.b.1')" />
        <span v-html="$t('pages.privacyPolicy.3.b.2')" />
        <span v-html="$t('pages.privacyPolicy.3.b.3')" />
        <ul>
          <li
            v-for="(line, index) in $t('pages.privacyPolicy.3.b.4')"
            :key="`3-b-4-line-${index}`"
          >
            <span v-html="line" />
          </li>
        </ul>
        <span v-html="$t('pages.privacyPolicy.3.b.5')" />
        <span v-html="$t('pages.privacyPolicy.3.b.6')" />
        <span v-html="$t('pages.privacyPolicy.3.b.7')" />
        <span v-html="$t('pages.privacyPolicy.3.b.8')" />
        <span v-html="$t('pages.privacyPolicy.3.b.9')" />
        <ul>
          <li
            v-for="(line, index) in $t('pages.privacyPolicy.3.b.10')"
            :key="`3-b-10-line-${index}`"
          >
            <span v-html="line" />
          </li>
        </ul>
        <span v-html="$t('pages.privacyPolicy.3.b.11')" />
        <span v-html="$t('pages.privacyPolicy.3.b.12')" />
        <span v-html="$t('pages.privacyPolicy.3.b.13')" />
        <span v-html="$t('pages.privacyPolicy.3.b.14')" />
        <span v-html="$t('pages.privacyPolicy.3.b.15')" />
        <span v-html="$t('pages.privacyPolicy.3.b.16')" />
        <span v-html="$t('pages.privacyPolicy.3.b.17')" />
        <span
          class="section--subtitle"
          v-html="$t('pages.privacyPolicy.3.c.title')"
        />
        <span v-html="$t('pages.privacyPolicy.3.c.1')" />
        <ul>
          <div
            v-for="(line, index) in $t('pages.privacyPolicy.3.c.2')"
            :key="`3-c-2-line-${index}`"
          >
            <li v-if="typeof line === 'string'">
              <span v-html="line" />
            </li>
            <ul v-else>
              <li
                v-for="(point, pointIndex) in line"
                :key="`3-c-2-line-${index}-point-${pointIndex}`"
              >
                <span v-html="point" />
              </li>
            </ul>
          </div>
        </ul>
        <span
          class="section--subtitle"
          v-html="$t('pages.privacyPolicy.3.d.title')"
        />
        <span v-html="$t('pages.privacyPolicy.3.d.1')" />
        <span v-html="$t('pages.privacyPolicy.3.d.2')" />
        <span v-html="$t('pages.privacyPolicy.3.d.3')" />
        <span v-html="$t('pages.privacyPolicy.3.d.4')" />

        <span class="section--title">
          {{ $t('pages.privacyPolicy.4.title') }}
        </span>
        <span v-html="$t('pages.privacyPolicy.4.1')" />
        <span v-html="$t('pages.privacyPolicy.4.2')" />

        <span class="section--title">
          {{ $t('pages.privacyPolicy.5.title') }}
        </span>
        <span
          class="section--subtitle"
          v-html="$t('pages.privacyPolicy.5.1')"
        />
        <span v-html="$t('pages.privacyPolicy.5.2')" />

        <span class="section--title">
          {{ $t('pages.privacyPolicy.6.title') }}
        </span>
        <span v-html="$t('pages.privacyPolicy.6.1')" />
        <ul>
          <li
            v-for="(line, index) in $t('pages.privacyPolicy.6.2')"
            :key="`6-2-line-${index}`"
          >
            <span v-html="line" />
          </li>
        </ul>
        <span v-html="$t('pages.privacyPolicy.6.3')" />

        <span class="section--title">
          {{ $t('pages.privacyPolicy.7.title') }}
        </span>
        <span v-html="$t('pages.privacyPolicy.7.1')" />
        <ol>
          <li
            v-for="(line, index) in $t('pages.privacyPolicy.7.2')"
            :key="`7-2-line-${index}`"
          >
            <span v-html="line" />
          </li>
        </ol>
        <span v-html="$t('pages.privacyPolicy.7.3')" />
        <ol type="a">
          <li
            v-for="(line, index) in $t('pages.privacyPolicy.7.4')"
            :key="`7-4-line-${index}`"
          >
            <span v-html="line" />
          </li>
        </ol>
        <span v-html="$t('pages.privacyPolicy.7.5')" />

        <span class="section--title">
          {{ $t('pages.privacyPolicy.8.title') }}
        </span>
        <span v-html="$t('pages.privacyPolicy.8.1')" />

        <span class="section--title">
          {{ $t('pages.privacyPolicy.9.title') }}
        </span>
        <span v-html="$t('pages.privacyPolicy.9.1')" />
      </div>
    </template>
  </layout>
</template>
<script>
import Layout from '@/components/layout/Layout';

export default {
  name: 'PrivacyPolicyScreen',
  components: {
    Layout,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.page-title {
  font-weight: 600;
  font-size: 38px;
  line-height: 48px;
}

.section {
  &--title {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    margin-top: 10px;
  }

  &--subtitle {
    font-weight: 500;
    font-size: 20px;
  }
}

::v-deep .bold {
  font-weight: 600;
}

.margin-container {
  margin-top: 150px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media only screen and (max-width: 1260px) {
  .margin-container {
    margin-top: 100px;
  }
}
</style>
